import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Container, Layout, Hero, PostNavigation, ImageAndContentBlock } from "components";

import "./post-single.scss";

const PostSingle = ({ data, pageContext, location }) => {
  const { post, previous, next } = data;
  const { title, content, date, categories, postInformation, featuredImage, imageAndContentBlock, seo } = post;

  return (
    <Layout className="post-single" pageTitle={title} seo={seo}>
      <Hero
        data={{
          content: `<h1>Blog</h1>`,
          compact: true,
          breadcrumbs: "above",
          // image: postInformation?.bannerImage || featuredImage,
        }}
        location={location}
      />
      <div className="post-single--inner">
        <Container containerwidth="layout--small" sectionspacing="section--small">
          {featuredImage && (
            <GatsbyImage image={getImage(featuredImage.node.localFile)} alt="" className="featured-image" />
          )}
          <article className="post-single--content">
            <p className="category-and-date">
              {categories?.nodes?.length > 0 && categories.nodes[0].name} • {date || ""}
            </p>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} className="post-content" />
          </article>
        </Container>
        <PostNavigation prevLink={previous} nextLink={next} backLink="/blog/" backLinkText="Blog Home" />
      </div>
      {imageAndContentBlock?.rowsimagecontentblock && <ImageAndContentBlock data={imageAndContentBlock} />}
    </Layout>
  );
};

export default PostSingle;

export const PostQueryById = graphql`
  query PostById($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpPost(id: { eq: $id }) {
      excerpt
      uri
      date(formatString: "DD/MM/YYYY")
      title
      id
      ...SEOPostQuery
      content
      categories {
        nodes {
          id
          name
          slug
        }
      }
      imageAndContentBlock {
        fieldGroupName
        rowsimagecontentblock {
          content
          contenttype
          fieldGroupName
          textoverlay
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1800)
              }
            }
          }
        }
      }
      postInformation {
        postDescription
        bannerImage {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1800)
            }
          }
        }
      }
      featuredImage {
        node {
          title
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 880, height: 586)
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
